// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Add, CircleQuestion, Trash } from 'grommet-icons';
import {
  Anchor,
  Box,
  Button,
  Select,
  Table, TableBody, TableCell, TableHeader, TableRow,
  Text,
} from 'grommet';
import { renderColumnTableCell } from '../shared/util/tableUtils';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import IDUtil from '../shared/util/IDUtil';
import CustomerListEditor from '../customers/CustomerListEditor';
import GLBMTooltip from '../shared/component/GLBMTooltip';
import Loader from '../shared/loader';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import { insertIf } from '../shared/util/BasicUtil';

const UserCustomerPage = (props) => {
  const [assigned, setAssigned] = useState(props.assigned ? props.assigned : []);
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);
  const [layer, setLayer] = useState(undefined);

  const onRemoveClick = (newSelectedIndex) => {
    setLayer('removeConfirm');
    setSelectedIndex(newSelectedIndex);
  };

  const layerClosed = () => {
    setLayer(undefined);
  };

  const onAddCustomerClicked = () => {
    setLayer('addCustomer');
  };

  const onCustomerListEditorApplied = (updatedCustomers) => {
    let i;
    const modifiedAssigned = [...assigned];
    const { role } = props;

    // look to see if we should add new items:
    for (i = 0; i < updatedCustomers.length; i += 1) {
      let found = false;
      for (let j = 0; j < modifiedAssigned.length; j += 1) {
        if (modifiedAssigned[j].customerId === updatedCustomers[i]) {
          found = true;

          // if a customer was found but currently marked for deletion, mark it 'UPDATE':
          if (modifiedAssigned[j].action === 'DELETE') {
            modifiedAssigned[j].action = 'UPDATE';
          }
        }
      }

      // if not found, add it as ADD:
      if (!found) {
        const newCustObj = {
          customerId: updatedCustomers[i],
          action: 'ADD',
        };
        if (role === 'ASM' || role === 'SERVICE_DEV') {
          newCustObj.asmRole = 'READ';
        }
        modifiedAssigned.push(newCustObj);
      }
    }

    // look for previously assigned customers that no longer are, mark as deleted:
    for (i = 0; i < modifiedAssigned.length; i += 1) {
      if (updatedCustomers.indexOf(modifiedAssigned[i].customerId) === -1) {
        modifiedAssigned[i].action = 'DELETE';
      }
    }

    setLayer(undefined);
    setAssigned(modifiedAssigned);
    props.onChange(modifiedAssigned);
  };

  const onCustomerListRemoveConfirmed = (data) => {
    const { index } = data;
    const modifiedAssigned = [...assigned];
    modifiedAssigned[index].action = 'DELETE';
    setLayer(undefined);
    setAssigned(modifiedAssigned);
    props.onChange(modifiedAssigned);
  };

  const onChangeRole = (customer, event) => {
    const modifiedAssigned = [...assigned];
    for (let i = 0; i < assigned.length; i += 1) {
      if (assigned[i].customerId === customer.customerId) {
        assigned[i].asmRole = event.value;
        if (assigned[i].action !== 'ADD') {
          assigned[i].action = 'UPDATE';
        }
      }
    }

    setAssigned(modifiedAssigned);
    props.onChange(modifiedAssigned);
  };

  const roles = useMemo(() => [{ value: 'READ', label: 'Read' }, { value: 'EDIT', label: 'Edit' }, { value: 'SUPER_EDIT', label: 'Super Edit' }], []);

  const getRoleValue = role => roles.filter(r => r.value === role)[0] || { value: 'READ', label: 'Read' };

  const renderConfirmationDetails = (assignedCustomer) => {
    const customer = props.customers[assignedCustomer.customerId];
    const { role } = props;
    return (
      <Box margin={{ top: 'small' }}>
        <GLBMNameValueList
          title='Selected Billing Account'
          data={[
            { label: 'Billing Account Name', value: customer ? customer.name : '-' },
            { label: 'Billing ID', value: customer ? customer.id : assignedCustomer.customerId },
            ...insertIf(role === 'ASM' || role === 'SERVICE_DEV', [{ label: 'Role', value: getRoleValue(assignedCustomer.asmRole).label }]),
          ]}
        />
      </Box>
    );
  };

  const renderLayer = () => {
    let result;
    const { customers } = props;
    if (layer) {
      if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={{ index: selectedIndex }}
            title='Are You Sure?'
            submitLabel='Yes, remove the Billing Account'
            cancelLabel='Cancel'
            onClose={layerClosed}
            onChange={onCustomerListRemoveConfirmed}
            details={renderConfirmationDetails(assigned[selectedIndex])}
          />
        );
      } else if (layer === 'addCustomer') {
        const customerMap = assigned
          .filter(c => c.action !== 'DELETE')
          .map(c => c.customerId);
        const customerKeys = Object.keys(customers).map(x => customers[x]);

        result = (
          customers
          && (
          <CustomerListEditor
            onClose={layerClosed}
            onChange={onCustomerListEditorApplied}
            items={customerKeys}
            selected={customerMap}
          />
          )
        );
      }
    }
    return result;
  };

  const noRowsElement = (totalCustomers) => {
    if (props.loading) {
      return (
        <Box direction='row' align='center' gap='small' justify='center' fill={true}>
          <Loader text='Loading Billing Accounts. Please wait ...' />
        </Box>
      );
    }
    if (totalCustomers === 0) {
      return (
        <ListPlaceholder
          emptyMessage='You have not added any Billing Accounts to this user.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }

    return '';
  };

  const roleHeaderWithHelp = () => (
    <Box direction='row'>
      <Box margin={{ right: 'small' }}>Role</Box>
      <GLBMTooltip
        content={(
          <Box>
            <li style={{ fontWeight: 'normal' }}>Choose Super Edit if you are responsible for creating and maintaining service configurations, pricing and capacity terms.</li>
            <li style={{ fontWeight: 'normal' }}>Choose Edit if you are responsible for pricing and capacity updates.</li>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <li style={{ fontWeight: 'normal' }}>Choose Read if you need visibility to the billing account but you don't need to update the billing configurations.</li>
          </Box>
)}
      >
        <Box>
          <CircleQuestion color='status-unknown' />
        </Box>
      </GLBMTooltip>
    </Box>
  );

  const {
    loading, role, customers, isRequest,
  } = props;
  const isServiceDev = role === 'SERVICE_DEV';
  const canEdit = isRequest || isServiceDev;
  const rows = [];

  if (!loading && assigned && assigned.length && customers) {
    assigned.forEach((customer, index) => {
      if (customer.action !== 'DELETE') {
        rows.push(
          <TableRow key={customer.customerId}>
            <TableCell>{customers && Object.prototype.hasOwnProperty.call(customers, customer.customerId) ? customers[customer.customerId].name : '-'}</TableCell>
            <TableCell>{customer.customerId}</TableCell>
            <TableCell>
              { canEdit ? (
                <Select
                  labelKey='label'
                  valueKey={{ key: 'value', reduce: true }}
                  placeholder='None'
                  options={[
                    { value: 'READ', label: 'Read' },
                    { value: 'EDIT', label: 'Edit' },
                    { value: 'SUPER_EDIT', label: 'Super Edit' },
                  ]}
                  value={customer.asmRole}
                  id={IDUtil.getId('ASMListViewListRoleDropDown', index)}
                  onChange={event => onChangeRole(customer, event)}
                />
              ) : getRoleValue(customer.asmRole).label}
            </TableCell>
            {(canEdit) && (
              <TableCell>
                <Anchor
                  icon={<Trash color='black' />}
                  label='Remove'
                  id={IDUtil.getId('ASMListViewListRemoveButton', index)}
                  onClick={() => onRemoveClick(index, customer.customerId)}
                  data-testid={IDUtil.getId('ASMListViewListRemoveButton', index)}
                  size='small'
                />
              </TableCell>
            )}
          </TableRow>,
        );
      }
    });
  }

  // if no rows:
  const noRows = noRowsElement(rows.length);

  const columns = [
    'Billing Account Name',
    'Billing ID',
    roleHeaderWithHelp(),
    ...insertIf(canEdit, ['Action']),
  ];

  return (
    <Box
      direction='column'
      margin={{ top: 'large' }}
      style={{ minHeight: '300px' }}
      flex='grow'
    >
      <GLBMHeading
        title='Assigned Billing Accounts'
        actions={[
          ...insertIf(canEdit, [<Button
            kind='toolbar'
            icon={<Add />}
            id={IDUtil.getId('RecipientListToolbarAddButton')}
            onClick={() => onAddCustomerClicked()}
            key='addButton'
            label='Add'
          />
          ])]}
        size='large'
        pad={{ vertical: 'small', horizontal: 'none' }}
        inline={true}
      />
      {canEdit && (role === 'ASM' || role === 'SERVICE_DEV') && (
      <Box margin={{ bottom: 'small' }}>

        <Text fill={true} margin='none'>
          {isRequest
            ? 'Select Billing Account(s) that you are assigned to and the role for each billing account.'
            : `Add billing accounts to give the ${role === 'SERVICE_DEV' ? 'Service Developer' : 'ASM'} access to service configuration, analytics and alerts`}
        </Text>

      </Box>
      )}
      <Box flex={true}>
        <Box
          border='top'
          flex={true}
        >
          <Table
            responsive={true}
            scrollable={false}
          >
            <TableHeader>
              {renderColumnTableCell(columns)}
            </TableHeader>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
          {noRows}
        </Box>
      </Box>
      {renderLayer()}
    </Box>
  );
};

UserCustomerPage.contextTypes = {
  router: PropTypes.object,
};

UserCustomerPage.propTypes = {
  assigned: PropTypes.array,
  customers: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  role: PropTypes.string,
  isRequest: PropTypes.bool,
  selectedIndex: PropTypes.number,
};

export default UserCustomerPage;
